<template>
  <div v-if="isVisible" class="overlay" @click="hide" />
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Overlay',
  computed: {
    ...mapGetters({
      isVisible: 'overlay/isVisible',
    }),
  },
  methods: {
    ...mapMutations({
      hide: 'overlay/hide',
    }),
  },
}
</script>
