<template>
  <LazyBackground
    :background-j-p-g="
      require(`~/assets/images/${backgroundPath}.jpg?resize&sizes[]=1260`)
    "
    :background-l-q-i-p="require(`~/assets/images/${backgroundPath}.jpg?lqip`)"
    :background-l-q-i-p-colors="
      require(`~/assets/images/${backgroundPath}.jpg?lqip-colors`)
    "
    :class="backgroundClass"
    :not-lazy="notLazy"
  />
</template>

<script>
import LazyBackground from './LazyBackground'

export default {
  name: 'LazyBackgroundWrapper',
  components: { LazyBackground },
  props: {
    backgroundPath: {
      type: String,
      required: true,
      default: '',
    },
    backgroundClass: {
      type: String,
      required: true,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
