<template>
  <LazyImg
    :image-j-p-g="
      require(`~/assets/images/${imagePath}.jpg?resize&sizes[]=480&sizes[]=640&sizes[]=940&sizes[]=1200`)
    "
    :image-web-p="require(`~/assets/images/${imagePath}.jpg?webp`)"
    :image-l-q-i-p="require(`~/assets/images/${imagePath}.jpg?lqip`)"
    :image-l-q-i-p-colors="
      require(`~/assets/images/${imagePath}.jpg?lqip-colors`)
    "
    :placeholder="placeholder"
    :image-alt="imageAlt"
    :sizes="sizes"
    :not-lazy="notLazy"
    :height="height"
    :width="width"
    :class="imageClass"
  />
</template>

<script>
import LazyImg from './LazyImg'

export default {
  name: 'LazyImgWrapper',
  components: { LazyImg },
  props: {
    imagePath: {
      type: String,
      required: true,
      default: '',
    },
    imageAlt: {
      type: String,
      required: true,
      default: null,
    },
    sizes: {
      type: String,
      required: true,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
    imageClass: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'true',
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },
}
</script>
