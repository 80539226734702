<template>
  <div
    ref="parallax"
    :data-rellax-speed="parallaxSpeed"
    class="frame"
    lazy="true"
  >
    <span />
  </div>
</template>

<script>
import Rellax from 'rellax'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  name: 'Frame',
  mixins: [UtilityMixin],
  props: {
    parallaxSpeed: {
      type: Number,
      default: -2,
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    const rellax = new Rellax(this.findRef(this.$refs.parallax), {
      center: true,
    })
  },
}
</script>
