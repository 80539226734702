<script>
export default {
  name: 'UtilityMixin',
  methods: {
    findRef(ref, x = 0) {
      return (
        ref ||
        new Promise((resolve) => {
          if (x > 100) {
            return resolve(document.querySelector('#app'))
          }
          setTimeout(() => {
            resolve(this.findRef(ref, ++x || 1))
          }, 100)
        })
      )
    },
  },
}
</script>
