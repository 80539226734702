import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

// Use custom error message for email fields
extend('email', {
  ...email,
  message: 'Please enter a valid email address',
})

// Use custom error message for required fields
extend('required', {
  ...required,
  message: 'This field is required',
})
