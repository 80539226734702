<template>
  <div>
    <App>
      <Header />
      <BlogHeader />
      <main class="main">
        <nuxt />
      </main>
      <Footer />
      <client-only>
        <cookie-law ref="cookie" transition-name="fade">
          <div class="Cookie__inner">
            <h3 class="Cookie__title">We got a cookie for you!</h3>
            <p class="Cookie__text">
              We use
              <a class="link" href="/privacy-policy">cookies</a> to provide you
              the best <br />
              experience on our website.
            </p>
            <div class="Cookie__actions">
              Scroll down to accept our
              <a class="link h-display-tablet-down" href="/privacy-policy"
                >cookies</a
              >
            </div>
          </div>
        </cookie-law>
      </client-only>
    </App>
  </div>
</template>

<script>
import App from '~/components/App.vue'
import Footer from '~/components/Footer.vue'
import BlogHeader from '~/components/BlogHeader'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  components: {
    App,
    Footer,
    BlogHeader,
  },
  mixins: [UtilityMixin],
  data() {
    return {
      scrollTop: 0,
      delta: 300,
    }
  },
  mounted() {
    const _this = this
    window.page = this
    this.$nextTick(() => {
      this.findRef(this.$refs.cookie).isAccepted()
        ? ''
        : window.addEventListener('scroll', _this.acceptCookies)
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  unmounted() {
    window.removeEventListener('scroll', this.acceptCookies)
  },
  methods: {
    acceptCookies() {
      if (this.scrollTop > this.delta) {
        this.findRef(this.$refs.cookie).accept()
        // this.$fb.enable()
        window.removeEventListener('scroll', this.acceptCookies)
      }
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop
    },
  },
}
</script>
