<template>
  <article :class="getClasses" class="editor">
    <header class="editor__header">
      <div class="editor__option-wrapper">
        <span class="editor__option -type-close" />
        <span class="editor__option -type-minimize" />
        <span class="editor__option -type-maximize" />
      </div>
      <h3 class="editor__title">
        {{ title }}
      </h3>
    </header>
    <div class="editor__body">
      <slot />
    </div>
  </article>
</template>

<script>
export default {
  name: 'MacWindow',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    extraClasses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    getClasses() {
      return this.extraClasses.join(' ')
    },
  },
}
</script>
