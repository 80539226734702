<template>
  <div class="hero__social">
    <ul class="list social__links">
      <template v-for="item in socialItems">
        <!-- eslint-disable -->
        <li :key="item.name" class="list__item social__item">
          <a
            :href="item.url"
            @click="$gtag.event('cta', 'click', 'Social' + item.name)"
            target="_blank"
            class="social__link"
            >{{ item.name }}</a
          >
        </li>
        <!-- eslint-enable -->
        <slot />
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HeroSocial',
  data() {
    return {
      socialItems: [
        {
          name: 'Twitter',
          url: 'https://twitter.com/graffino',
        },
        {
          name: 'Github',
          url: 'https://github.com/graffino',
        },
        {
          name: 'Linkedin',
          url: 'https://www.linkedin.com/company/graffino',
        },
      ],
    }
  },
}
</script>
