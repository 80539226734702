<template>
  <transition name="slide-fade">
    <section
      v-if="showOverlay"
      v-scroll-lock="showOverlay"
      class="header__overlay"
    >
      <div class="container">
        <div class="h-flex-justify-content-flex-start">
          <div class="overlay__content">
            <form class="search__form" @submit.prevent>
              <div class="search__input-wrapper">
                <input
                  v-model="searchQuery"
                  class="search__input"
                  type="text"
                  placeholder="Search..."
                  @change="search"
                />
                <div class="search__results-count">
                  We got {{ results.length }} result{{
                    results.length > 1 ? 's' : ''
                  }}
                  for you
                </div>
              </div>
              <div class="divider--thin" />
            </form>
            <ul class="search__article-list">
              <li
                v-for="result in results"
                :key="result.component.meta.title"
                class="search__results-item"
                @click="closeSearch()"
              >
                <nuxt-link :to="result.path" class="search__article">
                  {{ result.component.meta.title }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'SearchOverlay',
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: '',
      results: [],
    }
  },
  watch: {
    searchQuery(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.search()
      }
    },
  },
  mounted() {
    this.$nextTick(() => this.search())
  },
  methods: {
    ...mapMutations({
      closeSearch: 'overlay/closeSearch',
    }),
    search() {
      const routes = this.$router.options.routes
      const query = this.searchQuery
      const results = []
      routes.map((result) =>
        result.component().then((data) => {
          if (
            data.meta &&
            data.meta.title &&
            data.meta.indexed &&
            data.meta.keywords &&
            (result.path
              .split('-')
              .join(' ')
              .toLowerCase()
              .includes(query.toLowerCase()) ||
              data.meta.title.toLowerCase().includes(query.toLowerCase()) ||
              data.meta.keywords.toLowerCase().includes(query.toLowerCase()))
          ) {
            results.push({
              path: result.path,
              component: data,
            })
          }
        }),
      )
      this.$set(this, 'results', results)
    },
  },
}
</script>
