<template>
  <div
    class="
      h-display-flex
      h-flex-align-items-center
      h-flex-justify-content-center
      h-full-viewport-height
    "
  >
    <div class="editor">
      <div class="editor__header">
        <div class="editor__option-wrapper">
          <span class="editor__option -type-close" />
          <span class="editor__option -type-minimize" />
          <span class="editor__option -type-maximize" />
        </div>
      </div>
      <div class="editor__inner">
        <!-- prettier-ignore -->
        <pre class="code-wrapper">
          <span class="code -highlighted">
    _____
 __|___  |__  _____   ____    ______  ______  ____  ____   _   _____
|   ___|    ||     | |    \  |   ___||   ___||    ||    \ | | /     \
|   |  |    ||     \ |     \ |   ___||   ___||    ||     \| ||       |
|______|  __||__|\__\|__|\__\|___|   |___|   |____||__/\____| \_____/
  |_____|
          </span>
          <span class="code">
 _  _    ___  _  _
| || |  / _ \| || |
| || |_| | | | || |_
|__   _| |_| |__   _|
   |_|  \___/   |_|
          </span>
        </pre>
        <p class="text -color-error -size-small-tablet-down h-margin-bottom-2">
          ERROR 404 in https://graffino.com{{ currentUrl }} <br />
          We’re sorry but the page that you are searching for doesn't exist.
          <br />
          You can try to fix this problem using the instructions below.
        </p>
        <p class="text -color-error -size-small-tablet-down h-margin-bottom-8">
          1 problem (1 error, 0 warnings) <br />
          1 error and 0 warnings potentially fixable by going
          <a class="link -color-primary -type-inverted" href="/">back home</a>
          or typing 'exit'.
        </p>
        <form class="editor__form" @submit.prevent="retry">
          <span v-show="isCarret" class="editor__carret" />
          <input
            ref="cursor"
            v-model="command"
            :class="{ 'is-hidden-carret': isCarret }"
            class="editor__cursor"
            type="text"
            autofocus="true"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            @blur="restoreFocus"
            @keydown="disableCarret"
          />
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import UtilityMixin from '../mixins/UtilityMixin.vue'
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      command: '',
      isCarret: true,
    }
  },
  computed: {
    currentUrl() {
      return this.$route.path
    },
  },
  mounted() {
    this.restoreFocus()
  },

  methods: {
    retry() {
      if (this.command.includes('exit')) {
        this.$router.push('/')
      } else {
        this.command = ''
        this.isCarret = true
      }
    },
    restoreFocus() {
      this.findRef(this.$refs.cursor).focus()
    },
    disableCarret() {
      this.isCarret = false
    },
  },
}
</script>
