<template>
  <div
    class="lazy-background"
    :class="backgroundClass"
    :data-loaded="loading ? 'false' : 'true'"
    :data-background-image="`${backgroundJPG}`"
    :data-not-lazy="notLazy"
    :style="{
      'background-color': backgroundColor,
      'background-image': backgroundLQIPCSS,
    }"
  />
</template>

<script>
import lozad from 'lozad'

export default {
  name: 'LazyBackground',
  props: {
    backgroundJPG: {
      type: process.env.ENVIRONMENT === 'development' ? String : Object,
      default: null,
    },
    backgroundLQIP: {
      type: String,
      default: null,
    },
    backgroundLQIPColors: {
      type: process.env.ENVIRONMENT === 'development' ? String : Array,
      default: null,
    },
    backgroundClass: {
      type: String,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      imageWebp: null,
      // Set random color from dominant image colors as background
      backgroundColor:
        this.backgroundLQIPColors[
          Math.floor(Math.random() * this.backgroundLQIPColors.length)
        ],
      backgroundLQIPCSS: 'url(' + this.backgroundLQIP + ');',
    }
  },
  mounted() {
    // Remove style after loading
    this.$el.setAttribute('style', '')
    const setLoadingState = () => {
      this.loading = false
    }
    this.$el.addEventListener('load', setLoadingState)
    /* eslint-disable vue/no-deprecated-events-api */
    this.$once('hook:destroyed', () => {
      this.$el.removeEventListener('load', setLoadingState)
    })

    const observer = lozad(this.$el)
    observer.observe()
  },
}
</script>
