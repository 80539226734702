// plugins/bl-components.js

import Vue from 'vue'
import LazyBackgroundWrapper from '~/components/LazyBackgroundWrapper'
import LazyImgWrapper from '~/components/LazyImgWrapper'
import Frame from '~/components/Frame'
import MacWindow from '~/components/MacWindow'
import Modal from '~/components/Modal'

const components = {
  LazyBackgroundWrapper,
  LazyImgWrapper,
  Frame,
  MacWindow,
  Modal,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
