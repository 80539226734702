<template>
  <div class="thankyou">
    <blog-header />
    <hero-social />
    <div>
      <nuxt />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '~/components/Footer.vue'
import BlogHeader from '~/components/BlogHeader'
import HeroSocial from '../components/HeroSocial.vue'

export default {
  components: {
    Footer,
    'blog-header': BlogHeader,
    'hero-social': HeroSocial,
  },
}
</script>
