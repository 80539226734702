<template>
  <div ref="app">
    <Overlay />
    <slot />
  </div>
</template>

<script>
import Overlay from './Overlay'
export default {
  name: 'App',
  components: { Overlay },
  data() {
    return {
      scripts: [],
    }
  },
  beforeMount() {
    if (process.browser) {
      const scripts = document.createDocumentFragment()
      this.scripts.map((script) => {
        const link = document.createElement('script')
        link.setAttribute('src', script)
        scripts.appendChild(link)
      })
      document.getElementsByTagName('body')[0].appendChild(scripts)
    }
  },
  mounted() {
    // get the hash from the url and scroll to that element
    this.scrollToAnchor(window.$nuxt.$route.hash)
  },
  methods: {
    scrollToAnchor(hash) {
      if (hash) {
        const element = document.querySelector(hash)
        // if there is an element with the {hash} id
        if (element) {
          // scroll down to that element
          if ('scrollBehavior' in document.documentElement.style) {
            return window.scrollTo({
              top: element.getBoundingClientRect().top + window.scrollY,
              behavior: 'smooth',
            })
          } else {
            return window.scrollTo(
              0,
              element.getBoundingClientRect().top + window.scrollY,
            )
          }
        }
      }
    },
  },
}
</script>
