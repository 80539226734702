<template>
  <div>
    <transition name="fade">
      <div
        v-show="isOpen()"
        v-scroll-lock="isOpen()"
        class="modal-layout"
        @click="closeModal()"
      >
        <div class="modal-layout__square">
          <div class="modal-layout__loader">
            <div ref="spinner" class="modal-layout__loader -element" />
          </div>
          <header class="editor__header">
            <div class="editor__option-wrapper">
              <div class="modal-layout__close" @click="closeModal()">
                close<span class="modal-layout__button -close" />
              </div>
            </div>
          </header>
          <client-only>
            <iframe
              loading="lazy"
              :src="pipedriveLink"
              title="description"
              class="modal-layout__program"
              @load="closeLoader()"
            />
          </client-only>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      pipedriveLink: process.env.CALENDAR_LINK,
    }
  },
  methods: {
    ...mapState({
      isOpen: (state) => state.popup.isOpen,
    }),
    ...mapMutations({
      closeModal: 'popup/closeModal',
    }),
    closeLoader() {
      this.$refs.spinner.style.display = 'none'
    },
  },
}
</script>
