<template>
  <ul class="header__nav header__item h-align-right">
    <li
      class="menu__item"
      @click.prevent="
        toggleSearch()
        closeMenu()
        closeCategories()
      "
    >
      <a v-if="searchToggle()" href="#" class="menu__link" @click.prevent
        >Hide</a
      >
      <a v-if="!searchToggle()" href="#" class="menu__link" @click.prevent
        >Search</a
      >
    </li>
    <li class="menu__item">
      <nuxt-link
        v-smooth-scroll
        :to="`/#contact`"
        class="button"
        :target="_self"
        @click="$gtag.event('cta', 'click', 'Contact Us Header')"
      >
        <span class="button__inner">Contact Us</span>
      </nuxt-link>
    </li>
  </ul>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'HeaderNavigation',
  data() {
    return {
      ...mapGetters({
        searchToggle: 'overlay/searchToggle',
        categoriesToggle: 'overlay/categoriesToggle',
      }),
      navigationItems: [],
    }
  },
  created() {
    const routes = []
    this.$router.options.routes.map((route) =>
      route.component().then((component) => {
        if (component.meta && component.meta.category) {
          routes.push({
            path: route.path,
            component,
          })
        }
      }),
    )
    this.$set(this, 'navigationItems', routes)
  },
  methods: {
    ...mapMutations({
      toggleSearch: 'overlay/toggleSearch',
      toggleCategories: 'overlay/toggleCategories',
      closeMenu: 'overlay/hide',
      closeCategories: 'overlay/closeCategories',
      closeSearch: 'overlay/closeSearch',
    }),
  },
}
</script>
