<template>
  <transition name="slide-fade">
    <section
      v-if="showOverlay"
      v-scroll-lock="showOverlay"
      class="header__overlay"
    >
      <div class="container">
        <div class="h-padding-top-10 h-flex-justify-content-flex-start">
          <div>
            <ul class="search__article-list h-margin-top-4">
              <li
                v-for="result in results"
                :key="result.component.meta.title"
                class="search__results-item"
                @click="closeCategories()"
              >
                <nuxt-link :to="result.path" class="search__article">
                  {{ result.component.meta.title }}
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'CategoriesOverlay',
  props: {
    showOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      results: [],
    }
  },
  mounted() {
    const routes = []
    this.$router.options.routes.map((route) =>
      route.component().then((component) => {
        if (component.meta && component.meta.category) {
          routes.push({
            path: route.path,
            component,
          })
        }
      }),
    )
    this.$set(this, 'results', routes)
  },
  methods: {
    ...mapMutations({
      closeCategories: 'overlay/closeCategories',
    }),
  },
}
</script>
