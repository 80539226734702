<template>
  <div
    class="lazy-image"
    :class="imageClass"
    :data-loaded="loading ? 'false' : 'true'"
  >
    <picture
      :data-iesrc="imageJPG.src"
      :data-alt="imageAlt"
      class="lazy-image__wrapper"
      :data-not-lazy="notLazy"
    >
      <source :srcset="imageJPG.srcSet" :sizes="sizes" />
      <source :srcset="imageWebP" :sizes="sizes" />
      <img
        :width="width"
        :height="height"
        :alt="imageAlt"
        :src="imageLQIP"
        data-not-lazy="true"
      />
    </picture>
  </div>
</template>

<script>
import lozad from 'lozad'

export default {
  name: 'LazyImg',
  props: {
    imageJPG: {
      type: process.env.ENVIRONMENT === 'development' ? String : Object,
      default: null,
    },
    imageWebP: {
      type: String,
      default: null,
    },
    imageLQIP: {
      type: String,
      default: null,
    },
    imageLQIPColors: {
      type: process.env.ENVIRONMENT === 'development' ? String : Array,
      default: null,
    },
    imageAlt: {
      type: String,
      default: null,
    },
    imageClass: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'false',
    },
    sizes: {
      type: String,
      default: null,
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      imageWebp: null,
      // Set random color from dominant image colors as background
      backgroundColor:
        this.imageLQIPColors[
          Math.floor(Math.random() * this.imageLQIPColors.length)
        ],
      // CSS LQUIP
      cssLQIP: 'url(' + this.imageLQIP + ');',
    }
  },

  mounted() {
    const setLoadingState = () => {
      this.loading = false
    }
    this.$el.addEventListener('load', setLoadingState)
    /* eslint-disable vue/no-deprecated-events-api */
    this.$once('hook:destroyed', () => {
      this.$el.removeEventListener('load', setLoadingState)
    })

    const observer = lozad(this.$el)
    observer.observe()
  },
}
</script>
