import Vue from 'vue'
import VueBrowserUpdate from 'vue-browserupdate'

if (process.client) {
  Vue.use(VueBrowserUpdate, {
    options: {
      required: {
        i: '17',
        e: '17',
        f: '57',
        o: '60',
        s: '11',
        c: '49',
        y: '18.1',
        v: '1.10',
        uc: '11.5',
        samsung: '7.0',
        ios: '11.0',
      },
      no_permanent_hide: true,
      l: 'en',
      text_en:
        '<b class="buorg-mainmsg">Update your ({brow_name}) browser for the best viewing experience </b> <div class="buorgtop"><a{up_but}><span class="button__inner">Update Browser</span></a{up_but}></div><div><a{ignore_but}>Ignore</a></div>',
    },
  })

  Vue.browserUpdate.onshow(() => {
    document.body.style.overflowY = 'hidden'
  })

  Vue.browserUpdate.onclose(() => {
    document.body.style.overflowY = 'auto'
  })
}
