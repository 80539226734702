<template>
  <header
    id="header"
    :class="{ 'is-active': isActive }"
    class="header"
    @keydown.esc="
      closeMenu()
      closeSearch()
      closeCategories()
    "
  >
    <div class="header__wrapper">
      <div ref="headerInside" class="header__inside">
        <div class="header__nav header__item h-align-left">
          <ul v-if="windowWidth > 480" class="menu h-nowrap">
            <li class="menu__item">
              <a
                v-smooth-scroll="{ offset: 1 }"
                href="#header"
                class="menu__link"
                @click.prevent="
                  toggleCategories()
                  closeMenu()
                  closeSearch()
                "
                >{{ categoriesToggle() ? 'Hide ' : 'Services' }}</a
              >
            </li>
            <li
              v-for="item in navigationItems"
              :key="item.name"
              class="menu__item"
            >
              <nuxt-link
                v-smooth-scroll
                :to="`/${item.url}`"
                class="menu__link"
                :target="item.newTab === true ? '_blank' : '_self'"
                @click="navigationClicked"
              >
                {{ item.name }}
              </nuxt-link>
            </li>
          </ul>
          <div
            v-else
            class="header__hamburger header__item h-align-left"
            @click="handleMobileNavigation"
          >
            <a
              class="hamburger"
              :class="{ 'is-active': isMobileMenuOpen }"
              aria-label="Hamburger Menu"
            >
              <div class="hamburger__slice" />
              <div class="hamburger__slice" />
            </a>
          </div>
        </div>
        <div class="header__item h-align-center">
          <nuxt-link :to="`/#hero`" class="header__logo">
            <img
              :src="require('~/assets/images/logo-white.svg')"
              width="150"
              height="50"
              alt="Logo of Graffino"
              data-not-lazy="true"
            />
          </nuxt-link>
        </div>

        <nuxt-link
          :to="`/#hero`"
          class="h-show-desktop-down header__logo--left h-hide-small-down"
        >
          <img
            :src="require('~/assets/images/logo-initial.svg')"
            class="h-show-desktop-down"
            width="33"
            height="36"
            alt="Logo of Graffino"
          />
        </nuxt-link>
        <header-navigation />
      </div>
      <transition name="fade">
        <div v-if="isMobileMenuOpen" class="header__mobile">
          <ul class="menu h-nowrap">
            <li class="menu__item">
              <a
                v-smooth-scroll="{ offset: 1 }"
                href="#header"
                class="menu__link"
                @click.prevent="
                  toggleCategories()
                  closeMenu()
                  closeSearch()
                "
                >{{ categoriesToggle() ? 'Hide ' : 'Services' }}</a
              >
            </li>
            <li
              v-for="item in navigationItems"
              :key="item.name"
              class="menu__item"
            >
              <nuxt-link
                v-smooth-scroll
                :to="`/${item.url}`"
                class="menu__link"
                :target="item.newTab === true ? '_blank' : '_self'"
                @click="navigationClicked"
              >
                {{ item.name }}
              </nuxt-link>
            </li>
          </ul>
        </div>
      </transition>
    </div>
    <header-overlay :show-overlay="showOverlay()" />
    <search-overlay :show-overlay="showSearchOverlay()" />
    <categories-overlay :show-overlay="showCategoriesOverlay()" />
  </header>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import HeaderOverlay from './HeaderOverlay'
import SearchOverlay from './SearchOverlay'
import BlogHeaderNavigation from './BlogHeaderNavigation'
import CategoriesOverlay from './CategoriesOverlay'
import UtilityMixin from '../mixins/UtilityMixin.vue'

export default {
  name: 'Header',
  components: {
    CategoriesOverlay,
    'header-overlay': HeaderOverlay,
    'search-overlay': SearchOverlay,
    'categories-overlay': CategoriesOverlay,
    'header-navigation': BlogHeaderNavigation,
  },
  mixins: [UtilityMixin],
  emits: ['navigation-clicked'],
  data() {
    return {
      ...mapGetters({
        showOverlay: 'overlay/isVisible',
        showCategoriesOverlay: 'overlay/categoriesToggle',
        showSearchOverlay: 'overlay/searchToggle',
        categoriesToggle: 'overlay/categoriesToggle',
      }),
      isActive: false,
      lastScrollTop: 0,
      scrolled: 0,
      delta: 125,
      windowWidth: 0,
      isMobileMenuOpen: false,
      navigationItems: [
        {
          name: 'Careers',
          url: 'careers',
          newTab: true,
        },
      ],
    }
  },
  computed: {
    getTopPosition() {
      return (
        this.findRef(this.$refs.headerInside).getBoundingClientRect().top +
        window.scrollY
      )
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleMobileNavigation() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
      this.categoriesToggle() ? this.closeCategories() : ''
    },
    ...mapMutations({
      closeCategories: 'overlay/closeCategories',
      openCategories: 'overlay/openCategories',
      closeSearch: 'overlay/closeSearch',
      openSearch: 'overlay/openSearch',
      closeMenu: 'overlay/hide',
      openMenu: 'overlay/show',
      toggleMenu: 'overlay/toggle',
      toggleCategories: 'overlay/toggleCategories',
    }),
    handleScroll() {
      this.scrolled = window.scrollY
      if (this.scrolled > this.delta) {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.lastScrollTop = this.scrolled
    },
    navigationClicked() {
      this.$emit('navigation-clicked')
    },
  },
}
</script>
