var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header",class:{ 'is-active': _vm.isActive },attrs:{"id":"header"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.closeMenu()
    _vm.closeSearch()
    _vm.closeCategories()}}},[_c('div',{staticClass:"header__wrapper"},[_c('div',{ref:"headerInside",staticClass:"header__inside"},[_c('div',{staticClass:"header__nav header__item h-align-left"},[(_vm.windowWidth > 480)?_c('ul',{staticClass:"menu h-nowrap"},[_c('li',{staticClass:"menu__item"},[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({ offset: 1 }),expression:"{ offset: 1 }"}],staticClass:"menu__link",attrs:{"href":"#header"},on:{"click":function($event){$event.preventDefault();_vm.toggleCategories()
                _vm.closeMenu()
                _vm.closeSearch()}}},[_vm._v(_vm._s(_vm.categoriesToggle() ? 'Hide ' : 'Services'))])]),_vm._v(" "),_vm._l((_vm.navigationItems),function(item){return _c('li',{key:item.name,staticClass:"menu__item"},[_c('nuxt-link',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],staticClass:"menu__link",attrs:{"to":`/${item.url}`,"target":item.newTab === true ? '_blank' : '_self'},on:{"click":_vm.navigationClicked}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])],1)})],2):_c('div',{staticClass:"header__hamburger header__item h-align-left",on:{"click":_vm.handleMobileNavigation}},[_c('a',{staticClass:"hamburger",class:{ 'is-active': _vm.isMobileMenuOpen },attrs:{"aria-label":"Hamburger Menu"}},[_c('div',{staticClass:"hamburger__slice"}),_vm._v(" "),_c('div',{staticClass:"hamburger__slice"})])])]),_vm._v(" "),_c('div',{staticClass:"header__item h-align-center"},[_c('nuxt-link',{staticClass:"header__logo",attrs:{"to":`/#hero`}},[_c('img',{attrs:{"src":require('~/assets/images/logo-white.svg'),"width":"150","height":"50","alt":"Logo of Graffino","data-not-lazy":"true"}})])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"h-show-desktop-down header__logo--left h-hide-small-down",attrs:{"to":`/#hero`}},[_c('img',{staticClass:"h-show-desktop-down",attrs:{"src":require('~/assets/images/logo-initial.svg'),"width":"33","height":"36","alt":"Logo of Graffino"}})]),_vm._v(" "),_c('header-navigation')],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.isMobileMenuOpen)?_c('div',{staticClass:"header__mobile"},[_c('ul',{staticClass:"menu h-nowrap"},[_c('li',{staticClass:"menu__item"},[_c('a',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll",value:({ offset: 1 }),expression:"{ offset: 1 }"}],staticClass:"menu__link",attrs:{"href":"#header"},on:{"click":function($event){$event.preventDefault();_vm.toggleCategories()
                _vm.closeMenu()
                _vm.closeSearch()}}},[_vm._v(_vm._s(_vm.categoriesToggle() ? 'Hide ' : 'Services'))])]),_vm._v(" "),_vm._l((_vm.navigationItems),function(item){return _c('li',{key:item.name,staticClass:"menu__item"},[_c('nuxt-link',{directives:[{name:"smooth-scroll",rawName:"v-smooth-scroll"}],staticClass:"menu__link",attrs:{"to":`/${item.url}`,"target":item.newTab === true ? '_blank' : '_self'},on:{"click":_vm.navigationClicked}},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])],1)})],2)]):_vm._e()])],1),_vm._v(" "),_c('header-overlay',{attrs:{"show-overlay":_vm.showOverlay()}}),_vm._v(" "),_c('search-overlay',{attrs:{"show-overlay":_vm.showSearchOverlay()}}),_vm._v(" "),_c('categories-overlay',{attrs:{"show-overlay":_vm.showCategoriesOverlay()}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }