export const state = () => ({
  isOpen: false,
})

export const mutations = {
  openModal(state) {
    state.isOpen = true
  },
  closeModal(state) {
    state.isOpen = false
  },
}
